import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Col, Divider, Row, Input, Space } from 'antd'
import styled from 'styled-components'
import getOr from 'lodash/fp/getOr'

import { AMZ_LOGIN_URL } from '../../constants'
import { disconnectAmzAccount, submitAmzUrl } from '../../data/auth/actions'
import { getAuthUser } from '../../data/auth/selectors'
import { getFeedback } from '../../data/feedback/selectors'
import { LargeButton } from '../../ui/button'
import Message from '../../ui/message'

const FB_KEY_SUBMIT_AMZ_URL = 'fbSubmitAmzUrl'

const DisconnectButton = styled(Button)`
  height: 52px;
  border-radius: 10px;
  padding: 0 50px;
`

const SettingAccount = (props) => {
  const { user, onDisconnect, feedback, submitUrl } = props
  const amzVerify = getOr(false, 'amz_verify')(user)

  const  [verified, setVerified] = useState(amzVerify)
  const [form, setForm] = useState({
    amz_url: ''
  })

  const { amz_url } = form

  const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value })

  const handleSubmit = () => submitUrl(form).catch(() => setForm({ ...form, amz_url: '' }))

  const handleDisconnect = () => {
    onDisconnect().then((resp) => setVerified(getOr(false, 'amz_verify')(resp)))
  }

  const ConnectWrapper = () => {
    return (
      <>
        <LargeButton style={{ padding: '0 50px' }} type='primary'>
          <a href={AMZ_LOGIN_URL} target='_blank' rel='noreferrer'>
            Login with Amazon Flex account
          </a>
        </LargeButton>
        <Space>
        <Input name='amz_url' onChange={handleChange} value={amz_url} />
        <Button loading={feedback.isLoading} onClick={handleSubmit} type='primary'>
          Submit
        </Button>
        </Space>
      </>
    )
  }

  const DisconnectWrapper = () => {
    return (
      <>
        <Message title='Danger Zone' content='Proceed with caution' danger />
        <Divider />
        <DisconnectButton danger onClick={handleDisconnect}>
          Disconnect
        </DisconnectButton>
      </>
    )
  }

  return (
    <Row>
      <Col xs={24} md={12}>
        {verified ? <DisconnectWrapper /> : <ConnectWrapper />}
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => {
  return {
    user: getAuthUser(state),
    feedback: getFeedback(state, FB_KEY_SUBMIT_AMZ_URL)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onDisconnect: () => dispatch(disconnectAmzAccount({ feedbackToGeneral: 'Disconnected' })),
    submitUrl: (data) => dispatch(submitAmzUrl(data, { feedbackKey: FB_KEY_SUBMIT_AMZ_URL }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingAccount)
