import React from 'react'
import styled from 'styled-components'
import { URLS } from '../constants'
import SearchRunningStatus from '../modules/dashboard/search-running-status'

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 40px;
  line-height: 30px;
  text-align: center;
`

const DashboardHeaderTitle = (props) => {
  const { pathname } = props

  let title = 'Offers'
  if (pathname === URLS.INSTANT_OFFER) {
    title = 'Instant Offers'
  } else if (pathname === URLS.HISTORY) {
    title = 'History'
  } else if (pathname === URLS.SETTINGS) {
    title = 'Settings'
  } else if (pathname === URLS.SUBSCRIPTION) {
    title = 'Subscription'
  } else if (pathname === URLS.RECENT) {
    title = 'Recent Offers'
  } else if (pathname === URLS.SCHEDULED_OFFERS) {
    title = 'Scheduled Offers'
  } else if (pathname === URLS.LOGS) {
    title = 'Logs'
  } else if (pathname === URLS.MONITOR) {
    title = 'Monitor'
  } else if (pathname === URLS.STATION_ADDRESS) {
    title = 'Station Address'
  }

  return (
    <Wrapper>
      <div>
        <p style={{ fontSize: '20px', fontWeight: '600' }}>
          {title}
        </p>
        {pathname === URLS.DASHBOARD && <SearchRunningStatus />}
      </div>
    </Wrapper>
  )
}

export default DashboardHeaderTitle
