import axiosInstance from '../config'
import {
  handleError,
  setFeedback,
  setSuccessFeedback
} from '../feedback/actions'

export const getStationInfo =
  (stationId, options = {}) =>
  async (dispatch, getState) => {
    try {
      if (options.feedbackKey) {
        const feedback = {
          isLoading: true
        }

        dispatch(setFeedback(options.feedbackKey, feedback))
      }

      const token = localStorage.getItem('token')
      axiosInstance.defaults.headers.common.Authorization = token

      const response = await axiosInstance.get(
        `/station-address/get-station-info/${stationId}`
      )
      const responseData = response.data

      if (options.feedbackKey) {
        const feedback = {
          isLoading: false
        }

        dispatch(setFeedback(options.feedbackKey, feedback, true))
      }

      if (options.feedbackToGeneral) {
        dispatch(setSuccessFeedback(options.feedbackToGeneral))
      }

      return responseData
    } catch (error) {
      handleError(error, dispatch)

      if (options.feedbackKey) {
        const feedback = {
          isLoading: false
        }

        dispatch(setFeedback(options.feedbackKey, feedback, true))
      }
      throw error
    }
  }

export const submitStationAddress =
  (data, options = {}) =>
  async (dispatch, getState) => {
    try {
      if (options.feedbackKey) {
        const feedback = {
          isLoading: true
        }

        dispatch(setFeedback(options.feedbackKey, feedback))
      }

      const token = localStorage.getItem('token')
      axiosInstance.defaults.headers.common.Authorization = token

      const response = await axiosInstance.post(`/station-address/submit`, data)
      const responseData = response.data

      if (options.feedbackKey) {
        const feedback = {
          isLoading: false
        }

        dispatch(setFeedback(options.feedbackKey, feedback, true))
      }

      if (options.feedbackToGeneral) {
        dispatch(setSuccessFeedback(options.feedbackToGeneral))
      }

      return responseData
    } catch (error) {
      handleError(error, dispatch)

      if (options.feedbackKey) {
        const feedback = {
          isLoading: false
        }

        dispatch(setFeedback(options.feedbackKey, feedback, true))
      }
      throw error
    }
  }
