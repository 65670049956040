import axiosInstance from '../config'
import { setFeedback, setSuccessFeedback, handleError } from './actions'

export const is4xxError = (status) => {
  return status >= 400 && status < 500
}

export const startFeedback = (dispatch, options) => {
  if (options.feedbackKey) {
    const feedback = {
      isLoading: true
    }
    dispatch(setFeedback(options.feedbackKey, feedback))
  }
}

export const stopFeedback = (dispatch, options) => {
  const { feedbackKey, feedbackToGeneral } = options
  if (feedbackKey) {
    const feedback = {
      isLoading: false
    }
    dispatch(setFeedback(feedbackKey, feedback, true))
  }

  if (feedbackToGeneral) {
    dispatch(setSuccessFeedback(feedbackToGeneral))
  }
}

export const handleFeedbackError = (dispatch, error, feedbackKey) => {
  handleError(error, dispatch)
  stopFeedback(dispatch, feedbackKey)
}
