import React, { useState } from 'react'
import { Button, Image, Input, Space, Row, Col } from 'antd'

import { AMZ_LOGIN_URL, TUTORIAL_VIDEO_URL } from '../../constants'
import { LargeButton, LinkButton } from '../../ui/button'

const DashboardOnboard = (props) => {
  const { feedback, submitUrl } = props
  const [form, setForm] = useState({
    amz_url: ''
  })

  const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value })

  const handleSubmit = () => submitUrl(form).catch(() => setForm({ ...form, amz_url: '' }))

  const { amz_url } = form

  return (
    <Row gutter={16}>
      <Col xs={24} md={12}>
        <LargeButton style={{ padding: '0 50px' }} type='primary'>
          <a href={AMZ_LOGIN_URL} target='_blank' rel='noreferrer'>
            Login with Amazon Flex account
          </a>
        </LargeButton>

        <div>
        <p style={{ margin: '15px 0 10px' }}>
          Need help? Watch our tutorial video to learn how to get the Amazon Flex URL
        </p>
          <LinkButton
            style={{ padding: '0 20px' }}
            type='secondary'
            target='_blank'
            rel='noreferrer'
            to={TUTORIAL_VIDEO_URL}
          >
            Tutorial Video
          </LinkButton>
        </div>
      </Col>
      <Col xs={24} md={12}>
        <p style={{ marginBottom: '10px' }}>Copy the resulting URL to this input and Submit</p>
        <Image src='onboarding.png' />
        <Space>
          <Input name='amz_url' onChange={handleChange} value={amz_url} />
          <Button loading={feedback.isLoading} onClick={handleSubmit} type='primary'>
            Submit
          </Button>
        </Space>
      </Col>
    </Row>
  )
}

export default DashboardOnboard
