import { Checkbox, Col, Form, Input, Row } from 'antd'
import React, { useState } from 'react'
import { LargeButton } from '../../ui/button'
import { connect } from 'react-redux'
import {
  getStationInfo,
  submitStationAddress
} from '../../data/station-address/actions'
import Title from 'antd/es/typography/Title'

const StationAddressContainer = (props) => {
  const { onSubmit, getStationInfo } = props
  const [station, setStation] = useState(null)
  const [latitude, setLatitude] = useState(null)
  const [longitude, setLongitude] = useState(null)

  const [form] = Form.useForm()

  const handleSubmit = (values) => {
    const { station_id, coordinates, is_estimated } = values
    const [latitude, longitude] = coordinates.split(',')

    onSubmit({
      station_id,
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
      is_estimated
    })
  }

  const handleStationIdChange = (e) => {
    const stationId = e.target.value
    if (!stationId) {
      return
    }

    getStationInfo(stationId).then((sta) => setStation(sta))
  }

  const handleCoordinatesChange = (e) => {
    const coordinates = e.target.value
    if (!coordinates) {
      return
    }

    const [latitude, longitude] = coordinates.split(',')
    setLatitude(parseFloat(latitude))
    setLongitude(parseFloat(longitude))
  }

  return (
    <Row>
      <Col xs={24} md={16}>
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item
            name="station_id"
            label="Station ID"
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input onChange={handleStationIdChange} placeholder="Station ID" />
          </Form.Item>
          <Form.Item
            name="coordinates"
            label="Coordinates"
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input
              onChange={handleCoordinatesChange}
              placeholder="Latitude & Longitude (Comma Separated)"
            />
          </Form.Item>
          <Form.Item name="is_estimated" valuePropName="checked" label={null}>
            <Checkbox>Estimated</Checkbox>
            </Form.Item>

          <LargeButton
            block
            type="primary"
            htmlType="submit"
            style={{ marginTop: '20px' }}
          >
            Submit
          </LargeButton>
        </Form>

        {station && (
          <>
            <Title level={3} style={{ marginTop: '2rem' }}>
              Station Info
            </Title>
            <p>
              <strong>Station ID:</strong> {station.station_id}
            </p>
            <p>
              <strong>Station Name:</strong> {station.station_name}
            </p>
            <p>
              <strong>Latitude:</strong> {latitude}
            </p>
            <p>
              <strong>Longitude:</strong> {longitude}
            </p>
          </>
        )}
      </Col>
    </Row>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data) => dispatch(submitStationAddress(data, { feedbackToGeneral: 'Success' })),
    getStationInfo: (stationId) => dispatch(getStationInfo(stationId))
  }
}

export default connect(null, mapDispatchToProps)(StationAddressContainer)
